import ServicesContext from "./ServiceContext";
import AdminState from "./ContextStore/AdminState";
import SeoState from "./ContextStore/SeoState";
import ProjectState from "./ContextStore/ProjectState";
import ContactState from "./ContextStore/ContactState";
import AppointmentState from "./ContextStore/AppointmentState";
import CommentatorState from "./ContextStore/CommentatorState";

const ServiceProvider = ({ children }) => {
  return (
    <ServicesContext.Provider
      value={{
        AdminState: AdminState,
        SeoState: SeoState,
        ProjectState: ProjectState,
        ContactState: ContactState,
        AppointmentState: AppointmentState,
        CommentatorState: CommentatorState,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export default ServiceProvider;
